export const location = window.location;
export const hostname = location.hostname;
export const version = process.env.REACT_APP_VERSION;

export const isDeveloping = hostname === 'localhost';
export const isStaging = hostname.indexOf('staging') > -1;
export const isProduction = !isDeveloping && !isStaging;

export const ebhUrl = isDeveloping
  ? 'http://localhost:3000'
  : isStaging
  ? 'https://staging.energiebeheerders.nl'
  : 'https://energiebeheerders.nl';
export const graphqlUrl = isDeveloping
  ? 'http://localhost:4000/graphql'
  : isStaging
  ? 'https://api-staging.energiebeheerders.nl/api/v2/graphql'
  : 'https://api.energiebeheerders.nl/api/v2/graphql';
export const fileUrl = isDeveloping
  ? 'http://localhost:4000/files'
  : isStaging
  ? 'https://api-staging.energiebeheerders.nl/api/v2/files'
  : 'https://api.energiebeheerders.nl/api/v2/files';
export const humulusUrl = isDeveloping
  ? 'http://localhost:3001'
  : isStaging
  ? 'https://api-staging.energiebeheerders.nl/api/v2/pdf'
  : 'https://api.energiebeheerders.nl/api/v2/pdf';

export const installerAccountHost = isDeveloping
  ? 'http://localhost:2000'
  : isStaging
  ? 'https://ia-staging.energiebespaarders.nl'
  : 'https://partner.energiebespaarders.nl';

export const googleMapsKey =
  isDeveloping || isStaging
    ? 'AIzaSyCF0FsUPLY-JBiJlnQmIegbAKK2PsEP4jQ'
    : 'AIzaSyCsJIuZLHgZyp3Y7760HVZd4Kg40upNFKk';
export const logrocketAppId = 'vioyzp/de-energiebeheerders-jrimo';

export const unleashConfig = {
  host: 'https://gitlab.com/api/v4/feature_flags/unleash/5505588',
  instanceId: 't7R-6rQt_Nqgz96V6MNC',
};

export const migrationDate = new Date('2019-05-09');

/** Feature flag IDs, configured in https://gitlab.com/energiebespaarders/energiebeheerders/-/feature_flags */
export enum FeatureFlag {
  QuoteBuilder = 'quote-builder',
}
