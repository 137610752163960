import { useQuery } from '@apollo/client';
import { getSolutionsFromDomain, SOLUTION_DOMAIN_TOPICS } from '@energiebespaarders/constants';
import { Box, Flex, Placeholder, Separator } from '@energiebespaarders/symbols';
import React, { useMemo } from 'react';
import { useActiveHouseId } from '../hooks/useActiveHouseId';
import {
  Topic,
  IntakeTopic,
  SolutionDomain,
  SOLUTIONS,
  TOPICS_NL,
} from '@energiebespaarders/constants';
import { filterIrrelevantData, FILTER_DATA } from '@energiebespaarders/data-maps';
import {
  filterData as FilterData,
  filterDataVariables,
} from '@energiebespaarders/data-maps/types/generated/filterData';
import DataField from './DataField';

interface HouseInfoProps {
  mobile: boolean;
  solutionDomain?: SolutionDomain;
  keyPrefix: string;
}

const HouseInfo: React.FC<HouseInfoProps> = ({ mobile, solutionDomain }) => {
  const { activeHouseId } = useActiveHouseId();

  const { data: filterData, loading: filterLoading, error: filterError } = useQuery<
    FilterData,
    filterDataVariables
  >(FILTER_DATA, {
    skip: !activeHouseId,
    variables: { houseId: activeHouseId },
  });

  const relevantData = useMemo(
    (topic?: IntakeTopic) => {
      if (!filterData) return [];
      if (solutionDomain) {
        return filterIrrelevantData(
          (SOLUTION_DOMAIN_TOPICS[solutionDomain] as unknown) as IntakeTopic,
          filterData.house,
          getSolutionsFromDomain(solutionDomain),
          'house',
          'quote',
          false,
        );
      } else if (topic) {
        return filterIrrelevantData(topic, filterData.house, SOLUTIONS, 'house', 'quote', false);
      }
      return [];
    },
    [filterData, solutionDomain],
  );

  if (!filterData || filterLoading || filterError) return <Placeholder error={filterError} />;

  return solutionDomain ? (
    <Flex flexWrap="wrap" mx={-1} style={{ position: 'relative' }}>
      <Box width={mobile ? 1 : 1 / 2} px={mobile ? 0 : 1} mb={1}>
        <DataField
          manual={{ label: 'Postcode', value: filterData.house.address.zip }}
          color="grayBlack"
          readOnly
        />
      </Box>
      <Box width={mobile ? 1 : 1 / 2} px={mobile ? 0 : 1} mb={1}>
        <DataField
          manual={{ label: 'Stad', value: filterData.house.address.city }}
          color="grayBlack"
          readOnly
        />
      </Box>
      {relevantData.map((item: any, index: number) => (
        <Box
          key={`data-rel-${item.label}-${solutionDomain}-${index}`}
          width={mobile ? 1 : 1 / 2}
          px={mobile ? 0 : 1}
          mb={1}
        >
          <DataField dataItem={item} modelId={activeHouseId} readOnly />
        </Box>
      ))}
    </Flex>
  ) : (
    <>
      {Object.keys(TOPICS_NL).map(topic => {
        return (
          <div key={`info-${topic}`}>
            <Separator text={TOPICS_NL[topic as Topic]} my={2} />

            <Flex flexWrap="wrap" mx={-1} style={{ position: 'relative' }}>
              {relevantData.map((item: any) => (
                <Box
                  key={`data-topic-${item.label} ${solutionDomain}`}
                  width={mobile ? 1 : 1 / 2}
                  px={mobile ? 0 : 1}
                  mb={1}
                >
                  <DataField dataItem={item} modelId={activeHouseId} readOnly />
                </Box>
              ))}
            </Flex>
          </div>
        );
      })}
    </>
  );
};

export default HouseInfo;
