import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  Switch,
  Table,
  TextLink,
  Toast,
  Tooltip,
} from '@energiebespaarders/symbols';
import {
  Center,
  Color,
  FontWeight,
  Right,
  Small,
  Smaller,
} from '@energiebespaarders/symbols/helpers';
import {
  Calculator,
  Cancel,
  Edit,
  StocksDown,
  StocksUp,
  Sync,
} from '@energiebespaarders/symbols/icons/solid';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import _, { sum } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { useHasPricingPermission } from '../../../hooks/useHasPricingPermission';
import { delimit, fixUnit } from '../../../lib/utils';
import { INSTALLATION_BY_HOUSE_SOLUTION, UPDATE_INSTALLATION } from '../../../queries/installatron';
import {
  getInstallationByIdPrices,
  getInstallationByIdPricesVariables,
  getInstallationByIdPrices_installationById_items_supplier_installer,
} from '../../../types/generated/getInstallationByIdPrices';
import {
  installationByHouseSolution_installationByHouseSolution as t_installation,
  installationByHouseSolution_installationByHouseSolution_items as t_item,
} from '../../../types/generated/installationByHouseSolution';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import { InstallationItemInput } from '../../../types/graphql-global-types';
import { PriceType, recalculateInstallationPrices } from './recalculateInstallationPrices';

import { Solution } from '@energiebespaarders/constants';
import styled from 'styled-components';

type Installer = getInstallationByIdPrices_installationById_items_supplier_installer;

const TableWrapper = styled(Box)<{ $contentWidth: number }>`
  overflow-x: auto;
  > * {
    min-width: ${x => x.$contentWidth}px;
  }
`;

export const INSTALLATION_BY_ID_PRICES = gql`
  query getInstallationByIdPrices($installationId: ID!) {
    installationById(installationId: $installationId) {
      # Intentionally not fetching the id here to avoid problems with simultaneous fetches
      # causing the cached value to become undefined (OutOfDatePricePicker)
      items {
        product {
          id
        }
        supplier {
          id
          installer {
            id
            minimumRate
            name
          }
        }
        price {
          id
          minimumInstallerRate
          retailPrice
          purchasePrice
        }
        tieredRetailPrice
      }
    }
  }
`;

interface IColumn<T, P> {
  header: string;
  width: number;
  cell: (item: T, updateItem: (item: T) => void, tableProps: P) => ReactNode;
  footer?: (items: T[], tableProps: P) => ReactNode;
}

type ModifiableItem = t_item & {
  newRetailPrice: number;
  newPurchasePrice: number;
  include: boolean;
};

const getShownCurrentPrice = (item: ModifiableItem, targetPriceType: PriceType) =>
  targetPriceType === 'retail' ? item.retailPrice : item.purchasePrice;

const getShownTargetPrice = (item: ModifiableItem, targetPriceType: PriceType) =>
  targetPriceType === 'retail' ? item.newRetailPrice : item.newPurchasePrice;

const columns: IColumn<
  ModifiableItem,
  { targetPriceType: PriceType; isApplyingToBoth: boolean; hasMinimumRate: boolean }
>[] = [
  {
    header: 'Product',
    width: 15 / 32,
    cell: function Product(item, update) {
      return (
        <Flex flexWrap="nowrap">
          <input
            type="checkbox"
            checked={item.include}
            id={item.product.id}
            onChange={e => update({ ...item, include: e.target.checked })}
            style={{ margin: 'auto 0.5rem auto 0' }}
          />{' '}
          <label htmlFor={item.product.id}>{item.product.title}</label>
        </Flex>
      );
    },
    footer: function Total(_, { hasMinimumRate }) {
      const hasPricingPermission = useHasPricingPermission();
      return (
        <strong>
          {hasMinimumRate || hasPricingPermission ? (
            <>
              <Icon icon={StocksDown} solid mr={1} />
              Totaal (inkoop):
            </>
          ) : (
            <br />
          )}
          <br />
          <Icon icon={StocksUp} solid mr={1} />
          Totaal (verkoop):
          <br />
          <br />
          <br />
        </strong>
      );
    },
  },
  {
    header: 'Aantal',
    width: 3 / 32,
    cell: function Amount(item) {
      return (
        <>
          {item.amount} {fixUnit(item.product.priceUnit)}
        </>
      );
    },
  },
  {
    header: 'Huidig enkel',
    width: 3 / 32,
    cell: function CurrentSinglePrice(item, _, { targetPriceType }) {
      return <Right block>€ {delimit(getShownCurrentPrice(item, targetPriceType), 2)}</Right>;
    },
  },
  {
    header: 'Nieuw enkel',
    width: 3 / 32,
    cell: function Amount(item, _, { targetPriceType }) {
      return <Right block>€ {delimit(getShownTargetPrice(item, targetPriceType), 2)}</Right>;
    },
  },
  {
    header: 'Huidig totaal',
    width: 4 / 32,
    cell: function CurrentTotalPrice(item, _, { targetPriceType }) {
      return (
        <Right block>
          € {delimit(item.amount * getShownCurrentPrice(item, targetPriceType), 2)}
        </Right>
      );
    },
    footer: function CurrentTotalPriceSum(items) {
      return (
        <Right block>
          € {delimit(sum(items.map(item => item.amount * item.purchasePrice)), 2)}
          <br />€ {delimit(sum(items.map(item => item.amount * item.retailPrice)), 2)}
          <br /> <br /> <br />
        </Right>
      );
    },
  },
  {
    header: 'Nieuw totaal',
    width: 4 / 32,
    cell: function Amount(item, _, { targetPriceType }) {
      return (
        <Right block>
          € {delimit(item.amount * getShownTargetPrice(item, targetPriceType), 2)}
        </Right>
      );
    },
    footer: function Amount(items, { targetPriceType }) {
      const hasPricingPermission = useHasPricingPermission();

      const totalCurPurchasePrice = sum(items.map(item => item.amount * item.purchasePrice));
      const totalNewPurchasePrice = sum(items.map(item => item.amount * item.newPurchasePrice));

      const totalCurRetailPrice = sum(items.map(item => item.amount * item.retailPrice));
      const totalNewRetailPrice = sum(items.map(item => item.amount * item.newRetailPrice));

      const isLower =
        totalCurPurchasePrice > totalNewPurchasePrice || totalCurRetailPrice > totalNewRetailPrice;

      const increasePct =
        (targetPriceType === 'purchase'
          ? totalNewPurchasePrice / totalCurPurchasePrice
          : totalNewRetailPrice / totalCurRetailPrice) - 1;

      // const margin = 1 - totalNewPurchasePrice / totalNewRetailPrice;
      // price.retailPrice / price.purchasePrice * 100 - 100
      const markupOld = totalCurRetailPrice / totalCurPurchasePrice - 1;
      const markupNew = totalNewRetailPrice / totalNewPurchasePrice - 1;
      const markupDiff = Math.round(1000 * (markupNew - markupOld)) / 1000;

      return (
        <Color c={isLower ? themify('red') : 'inherit'}>
          <Right block>
            <FontWeight weight={targetPriceType === 'purchase' ? '500' : 'normal'}>
              € {delimit(totalNewPurchasePrice, 2)}
            </FontWeight>
            <br />
            <FontWeight weight={targetPriceType === 'retail' ? '500' : 'normal'}>
              € {delimit(totalNewRetailPrice, 2)}
            </FontWeight>
            <br />
            <Small>
              Verschil:{' '}
              <Color c={isLower ? 'inherit' : themify('green')}>
                {increasePct >= 0 ? '+' : ''}
                {delimit(100 * increasePct, 1)}%
              </Color>
              <br />
              {hasPricingPermission && (
                <Tooltip
                  content={`Van ${delimit(100 * markupOld, 1)}% naar ${delimit(
                    100 * markupNew,
                    1,
                  )}% (${markupDiff >= 0 ? '+' : ''}${delimit(100 * markupDiff, 1)}%)`}
                >
                  <>
                    Markup:{' '}
                    <Color
                      c={
                        markupNew <= 0
                          ? themify('red')
                          : markupDiff === 0
                          ? 'inherit'
                          : markupDiff < 0
                          ? themify('orange')
                          : themify('green')
                      }
                    >
                      {delimit(100 * markupNew, 1)}%
                    </Color>
                  </>
                </Tooltip>
              )}
            </Small>
          </Right>
        </Color>
      );
    },
  },
];

const DEFAULT_PRICE_TYPE: PriceType = 'retail';

const InstallationPriceIncreaseModal = ({
  installation,
  onClose,
}: {
  installation: t_installation;
  onClose: () => void;
}) => {
  const { activeHouseId } = useActiveHouseId();
  const [targetPrice, setTargetPrice] = useState<number>();
  const [targetPriceType] = useState<PriceType>(DEFAULT_PRICE_TYPE);
  // TODO: could set a percentage for the total mark-up to use, instead of an all or nothing switch
  /** Applying price increase to both retail and purchased price, or just retailPrice */
  const [isApplyingToBoth, setIsApplyingToBoth] = useState(true);
  const hasPricingPermission = useHasPricingPermission();

  const [modifiableItems, setModifiableItems] = useState<ModifiableItem[]>([]);

  // Update items whenever installation changes
  useEffect(
    () =>
      setModifiableItems(
        installation.items.map(item => ({
          ...item,
          newRetailPrice: item.retailPrice,
          newPurchasePrice: item.purchasePrice,
          newPriceType: DEFAULT_PRICE_TYPE,
          include: true,
        })),
      ),
    [installation],
  );

  const recalculate = useCallback(
    (items: ModifiableItem[], target: number, targetType: PriceType, applyToBoth: boolean) =>
      setModifiableItems(recalculateInstallationPrices(items, target, targetType, applyToBoth)),
    [],
  );

  const updateItem = useCallback(
    (item: ModifiableItem) => {
      const newItems = [...modifiableItems];
      newItems.splice(
        newItems.findIndex(i => i.product.id === item.product.id),
        1,
        item,
      );
      recalculate(newItems, targetPrice || 0, targetPriceType, isApplyingToBoth);
    },
    [isApplyingToBoth, modifiableItems, recalculate, targetPrice, targetPriceType],
  );

  const handleUpdateTarget = useCallback(
    (target: number, type: PriceType, isApplyingToBoth: boolean) => {
      setIsApplyingToBoth(isApplyingToBoth);
      // If the target is of type purchasePrice, recompute it to the corresponding retailPrice
      if (type === 'purchase') {
        const newItems = recalculateInstallationPrices(modifiableItems, target, type, true);
        const totalRetailPrice = sum(newItems.map(i => i.amount * i.newRetailPrice));
        setTargetPrice(Math.round(totalRetailPrice * 100) / 100);
        setModifiableItems(newItems);
      } else {
        setTargetPrice(target);
        recalculate(modifiableItems, target, type, isApplyingToBoth);
      }
    },
    [modifiableItems, recalculate],
  );

  const handleToggleApplyToBoth = useCallback(() => {
    if (!hasPricingPermission) {
      return window.alert(
        'Niet toegestaan: Alleen NS/DEV mogen de verkoopprijs behouden bij het verhogen van de inkoopprijs',
      );
    }
    const newValue = !isApplyingToBoth;
    if (targetPrice) {
      handleUpdateTarget(targetPrice, targetPriceType, newValue);
    } else {
      setIsApplyingToBoth(newValue);
    }
  }, [handleUpdateTarget, hasPricingPermission, isApplyingToBoth, targetPrice, targetPriceType]);

  const currentTotal = useMemo(
    () => ({
      retail: sum(installation.items.map(i => i.amount * i.retailPrice)),
      purchase: sum(installation.items.map(i => i.amount * i.purchasePrice)),
    }),
    [installation],
  );

  const [updateInstallation, { loading, error }] = useMutation<
    updateInstallation,
    updateInstallationVariables
  >(UPDATE_INSTALLATION, {
    // Refetch installation to re-create the constraint messages
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: INSTALLATION_BY_HOUSE_SOLUTION,
        variables: { houseId: activeHouseId, solution: installation.solution },
      },
    ],
  });

  const handleSubmit = useCallback(async () => {
    const installationItems: InstallationItemInput[] = modifiableItems.map(i => ({
      // Ceiling instead of rounding, since otherwise the minimum rate might not be met
      retailPrice: Math.ceil(i.newRetailPrice * 100) / 100,
      purchasePrice: Math.ceil(i.newPurchasePrice * 100) / 100,
      productId: i.product.id,
      supplierId: i.supplier.id,
      amount: i.amount,
    }));
    await updateInstallation({
      variables: {
        id: installation.id,
        installation: {
          id: installation.id,
          items: installationItems,
          solution: installation.solution,
        },
      },
    });
  }, [installation.id, installation.solution, modifiableItems, updateInstallation]);

  const { data: installationData } = useQuery<
    getInstallationByIdPrices,
    getInstallationByIdPricesVariables
  >(INSTALLATION_BY_ID_PRICES, {
    variables: { installationId: installation.id },
    fetchPolicy: 'cache-and-network',
  });

  const installersWithMinRate = installationData?.installationById?.items
    .filter(inst => typeof inst.supplier.installer?.minimumRate === 'number')
    .map(inst => inst?.supplier.installer as Installer)
    .filter((inst, idx, arr) => idx === arr.findIndex(inst2 => inst.id === inst2.id)); // filter out dupes

  const handleRestoreDefaultPrices = useCallback(async () => {
    if (!installationData?.installationById?.items.length)
      return window.alert('Er is iets misgegaan');

    const defaultPrices = installationData!.installationById!.items.map(i => i.price);
    const installationItems: InstallationItemInput[] = modifiableItems.map((item, index) => ({
      retailPrice: defaultPrices[index].retailPrice,
      purchasePrice: defaultPrices[index].purchasePrice,
      productId: item.product.id,
      supplierId: item.supplier.id,
      amount: item.amount,
    }));

    await updateInstallation({
      variables: {
        id: installation.id,
        installation: {
          id: installation.id,
          items: installationItems,
          solution: installation.solution,
        },
      },
    });
  }, [
    installation.id,
    installation.solution,
    installationData,
    modifiableItems,
    updateInstallation,
  ]);

  const hasMinimumRate = !!(
    installersWithMinRate?.some(i => i.minimumRate) ||
    installationData?.installationById?.items.some(i => i.price.minimumInstallerRate)
  );

  return (
    <Modal
      isOpen
      title="Productprijzen omrekenen"
      onRequestClose={onClose}
      size="lg"
      buttons={[
        {
          label: 'Sluiten',
          bgColor: 'red',
          onClick: onClose,
          iconStart: Cancel,
        },
        {
          label: 'Standaardprijzen herstellen',
          bgColor: 'blue',
          onClick: handleRestoreDefaultPrices,
          iconStart: Sync,
          loading,
        },
        {
          label: 'Invullen',
          bgColor: 'green',
          disabled: !targetPrice || currentTotal[targetPriceType] > targetPrice,
          title:
            !targetPrice || currentTotal[targetPriceType] > targetPrice
              ? 'Gewenst bedrag is lager dan huidig totaalbedrag'
              : 'Huidige productprijzen met berekende productprijzen vervangen',
          onClick: handleSubmit,
          minIconSize: '1em',
          iconStart: Edit,
          loading,
          error,
        },
      ]}
    >
      <Flex flexWrap="wrap">
        <Box width={1} mb={1}>
          <Small>
            Hier kun je de prijs van een offerte verhogen, om bijvoorbeeld aan het minimumtarief van
            de installateur te voldoen.
            {!hasPricingPermission && (
              <>
                <br />
                Let op: Dit verhoogt standaard zowel de verkoop- als de inkoopprijs, dus ongeschikt
                om de marge te verhogen.
              </>
            )}
            <br />
            De prijsverhoging wordt over alle producten gelijk verdeeld om het gewenste bedrag te
            halen. De-selecteer producten om hun originele prijs te behouden.
          </Small>
        </Box>

        <Box width={1}>
          {installersWithMinRate?.map(installer => (
            <Toast
              key={installer.id}
              type="info"
              message={`${installer.name} heeft een minimum inkoopprijs van € ${delimit(
                installer.minimumRate!,
                2,
              )}. Klik hier om deze te gebruiken.`}
              width="100%"
              toastId={44}
              onClick={() => handleUpdateTarget(installer.minimumRate!, 'purchase', true)}
            />
          ))}
          {installationData?.installationById?.items.map((item, index) =>
            !item.price.minimumInstallerRate ? null : (
              <Toast
                type="info"
                message={`"${installation.items[index]?.product.title}" heeft bij ${
                  item.supplier.installer?.name
                } een minimum inkoopprijs van € ${delimit(
                  item.price.minimumInstallerRate,
                  2,
                )}. Klik hier om deze te gebruiken.`}
                width="100%"
                toastId={55}
                onClick={() =>
                  handleUpdateTarget(item.price.minimumInstallerRate!, 'purchase', true)
                }
              />
            ),
          )}
        </Box>

        <Box width={[1, 1, 1 / 2, 1 / 3, 1 / 4]} marginLeft="auto">
          <Input
            autoFocus
            label="Gewenste totale prijs (excl. btw)"
            value={targetPrice || ''}
            type="number"
            onChange={e =>
              handleUpdateTarget(Number(e.target.value), targetPriceType, isApplyingToBoth)
            }
            addonContent="€"
            addonSide="start"
            error={
              !targetPrice || currentTotal[targetPriceType] > targetPrice
                ? 'Lager dan huidig totaalbedrag'
                : null
            }
          />

          {installation.solution === Solution.AirToAirHeatPump && ( // TODO: Remove this when the Airco-actie is done
            <TextLink
              onClick={() =>
                handleUpdateTarget(
                  _.round(currentTotal[targetPriceType] * 1.35, 2),
                  targetPriceType,
                  false,
                )
              }
            >
              <u>+35% (Budget Thuis Airco-actie)</u>
              {/* Note: Used to be +17% but raised to 35% due to the 6% margin experiment */}
            </TextLink>
          )}
        </Box>

        <Box width={[1, 1, 1 / 2, 1 / 3]} px={1} marginRight="auto">
          <Center block>
            <Smaller>
              <Color c={themify('grayDark')}>Inkoopprijs behouden (alleen voor Planning)</Color>
            </Smaller>
          </Center>
          {/* When increasing retail price to round it off, no need to increase purchase price, but may be desired */}
          {/* When increasing purchase price to meet the installer's min. rate: we do want to increase the retail price too */}
          {/* When increasing purchase price, we *always* want to increase the retail price (?) */}
          <Switch
            onLabel="Wel behouden"
            offLabel="Niet behouden"
            isOn={isApplyingToBoth}
            toggleSwitch={handleToggleApplyToBoth}
          />
        </Box>

        <TableWrapper width={1} mt={1} $contentWidth={800}>
          <Table layout={columns.map(col => col.width)}>
            <Table.Row isHeader>
              {columns.map((col, index) => (
                <Table.Cell
                  key={col.header}
                  cellPadding="0.33rem"
                  textAlign={index > 1 ? 'right' : undefined}
                >
                  {col.header}
                </Table.Cell>
              ))}
            </Table.Row>
            {modifiableItems.map(item => (
              <Table.Row
                key={item.product.id}
                rowColor={item.include ? undefined : themify('darkGray')}
              >
                {columns.map(col => (
                  <Table.Cell key={col.header}>
                    {col.cell(item, updateItem, {
                      targetPriceType,
                      isApplyingToBoth,
                      hasMinimumRate,
                    })}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
            <Table.Row isFooter>
              {columns.map(col => (
                <Table.Cell key={col.header}>
                  {col.footer?.(modifiableItems, {
                    targetPriceType,
                    isApplyingToBoth,
                    hasMinimumRate,
                  })}
                </Table.Cell>
              ))}
            </Table.Row>
          </Table>
        </TableWrapper>
      </Flex>
    </Modal>
  );
};

/**
 *  Allows you to increase the retail and/or purchase price of selected products, to meet a minimum installer rate or to round up a price for whatever reason
 *  Based on this sheet: https://docs.google.com/spreadsheets/d/1YKzD7n9-CFMxe6lod_l9Y-wswt095diJNjvo4QJTuXk/edit#gid=0
 */
const RetailPriceIncrease = ({ installation }: { installation: t_installation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Button
        label="Omrekenen"
        bgColor="blue"
        inverse
        disabled={installation.items.length === 0}
        iconStart={Calculator}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <InstallationPriceIncreaseModal installation={installation} onClose={handleClose} />
      )}
    </>
  );
};

export default RetailPriceIncrease;
