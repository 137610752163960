import { SOLUTIONS_NL } from '@energiebespaarders/constants';
import { Box, Flex, Icon, Spacer } from '@energiebespaarders/symbols';
import { Medium, Small } from '@energiebespaarders/symbols/helpers';
import { PaperAirplane, SpecialCheck, UserCircle } from '@energiebespaarders/symbols/icons/solid';
import dayjs from 'dayjs';
import { quoteModalQuote_quoteById } from '../../../../types/generated/quoteModalQuote';
import { Expiration } from '../../../installatron';
import AcceptedField from '../Accepted';

export type QuoteModalGeneralInfoProps = {
  quote: quoteModalQuote_quoteById;
  solutionAccepted?: boolean;
};

export function QuoteModalGeneralInfo({
  quote,
  solutionAccepted,
}: QuoteModalGeneralInfoProps): JSX.Element {
  const redundant = solutionAccepted && !quote.isAccepted;
  const operator = quote.operator;

  return (
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} mb={1}>
        <Icon icon={UserCircle} solid fill="gray" width="1.25rem" mr={2} />
        <Small>
          Aangemaakt door{' '}
          <Medium>{operator ? `${operator.firstName} ${operator.lastName}` : 'Onbekend'}</Medium>
          {quote.created && (
            <span>
              {' '}
              op
              <Medium>
                {' '}
                {dayjs(quote.created).format('dddd D MMMM YYYY (HH:mm)').toLowerCase()}
              </Medium>
            </span>
          )}
        </Small>
      </Box>
      {quote.isSent && (
        <Box width={[1, 1, 1 / 2]} mb={1}>
          <Icon icon={PaperAirplane} solid fill="gray" width="1.25rem" mr={2} />
          <Small>
            Verzonden op
            <Medium> {dayjs(quote.sentOn).format('dddd D MMMM YYYY (HH:mm)').toLowerCase()}</Medium>
          </Small>
        </Box>
      )}
      {quote.isSent && (
        <Box width={[1, 1, 1 / 2]}>
          <Expiration
            expirationDate={quote.expirationDate}
            quoteId={quote.id}
            updatedRetailPrices={quote.updatedRetailPrices}
            quoteItems={quote.items}
          />
        </Box>
      )}
      <Box width={[1, 1, 1 / 2]}>
        {quote.acceptedSubjectToFunding && (
          <Box>
            <Icon icon={SpecialCheck} solid inline fill="gray" width="1.25rem" />
            <Spacer amount={2} />
            <Small>
              Akkoord O.V.V. financiering gekregen op{' '}
              <Medium>
                {' '}
                {dayjs(quote.acceptedSubjectToFunding.date)
                  .format('dddd D MMMM YYYY (HH:mm)')
                  .toLowerCase()}
              </Medium>
            </Small>
          </Box>
        )}
        {quote.isAccepted && <AcceptedField quote={quote} />}
      </Box>
      {redundant && (
        <Box width={[1, 1, 1 / 2]}>
          <Icon icon={SpecialCheck} solid inline fill="gray" width="1.25rem" />
          <Spacer amount={2} />
          <Small>
            Er is al een akkoord op een andere offerte voor {SOLUTIONS_NL[quote.solution]}
          </Small>
        </Box>
      )}
      {/* TODO: link to Sales sheet with delivery times and more insight into capacity slots */}
      {/* {!redundant && !isCancelled && availableInstallationDate && (
      <Box width={[1, 1, 1 / 2]}>
        <Icon icon={CalendarNext} solid inline fill="blue" width="1.25rem" />
        <Spacer amount={2} />
        <Small>
          Installatietermijn:{' '}
          <strong>{formatInstallationTerm(availableInstallationDate)}</strong>
        </Small>
      </Box>
    )} */}
    </Flex>
  );
}
