import React from 'react';
import HouseUploader from '../HouseUploader';
import HouseGallery from '../HouseGallery';
import { Icon, Modal } from '@energiebespaarders/symbols';
import { Gray, Small, TinyHeading } from '@energiebespaarders/symbols/helpers';
import useToaster from '../../hooks/useToaster';
import { Info } from '@energiebespaarders/symbols/icons/solid';
import { Link } from 'react-router-dom';
import {
  ImageUploadScaleNL,
  ImageUploadScalePreset,
  IMAGE_UPLOAD_SCALE_PREFERENCE_KEY,
} from '../../domains/Config';

type UploadModalProps = {
  isOpen: boolean;
  mobile?: boolean;
  houseId: string;
  closeModal: () => void;
  onSave: () => void;
  topic: string;
  dataItem: {
    key: string;
    label?: string;
    value?: number | string | boolean;
    unit?: string;
    requiredPhotos?: string[];
  };
  getExtraTags?: () => string[];
};

const UploadModal: React.FC<UploadModalProps> = ({
  closeModal,
  dataItem,
  houseId,
  isOpen,
  mobile,
  onSave,
  topic,
  getExtraTags,
}) => {
  const toast = useToaster();

  const imageRescalePreference =
    (localStorage.getItem(IMAGE_UPLOAD_SCALE_PREFERENCE_KEY) as ImageUploadScalePreset) || 'large';

  return (
    <Modal
      key={`modal-${dataItem.label}`}
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={`Foto's uploaden voor ${dataItem.label}`}
      mobile={mobile}
      timeout={mobile ? 250 : undefined}
      buttons={[
        {
          bgColor: 'red',
          inverse: true,
          label: 'Terug',
          onClick: () => closeModal(),
          m: 0,
        },
        {
          bgColor: 'green',
          inverse: true,
          label: 'Opslaan',
          onClick: () => {
            onSave();
            toast({
              type: 'success',
              message: `Foto's opgeslagen voor ${dataItem.label}`,
            });
            setTimeout(() => closeModal(), 100);
          },
        },
      ]}
    >
      {dataItem.requiredPhotos && (
        <div>
          <TinyHeading>Benodigde foto's</TinyHeading>
          <ol>
            {dataItem.requiredPhotos.map((photo, index) => (
              <li key={`${dataItem}-photo-${index}`}>{photo}</li>
            ))}
          </ol>
        </div>
      )}

      <HouseUploader
        houseId={houseId}
        tags={['intake', dataItem.key, topic]}
        getExtraTags={getExtraTags}
      />
      <HouseGallery houseId={houseId} tags={['intake', dataItem.key, topic]} exclusiveMatch />
      <br />
      <Small>
        <Gray>
          <Icon icon={Info} fill="blue" /> Je kunt de kwaliteit voor het uploaden van foto's
          veranderen in het <Link to="/config">Instellingen menu</Link>.
          <br />
          Gekozen optie: <b>{ImageUploadScaleNL[imageRescalePreference]}</b>
        </Gray>
      </Small>
    </Modal>
  );
};
export default UploadModal;
