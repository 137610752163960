import { gql, useMutation, useQuery } from '@apollo/client';
import { ProductCategory, Solution } from '@energiebespaarders/constants';
import { Button } from '@energiebespaarders/symbols';
import { LionFace } from '@energiebespaarders/symbols/icons/solid';
import _ from 'lodash';
import { useCallback } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { INSTALLATION_BY_HOUSE_SOLUTION, UPDATE_INSTALLATION } from '../../../queries/installatron';
import { houseIng, houseIngVariables } from '../../../types/generated/houseIng';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import { InstallationItemInput } from '../../../types/graphql-global-types';
import tieredPrices from '@energiebespaarders/constants/tieredPrices';

export const HOUSE_ING = gql`
  query houseIng($houseId: ID!) {
    house(id: $houseId) {
      id
      isINGHouse
    }
  }
`;

export function needsINGPriceReduction(solution: Solution) {
  return ([
    Solution.PvSystem,
    Solution.HybridHeatPump,
    Solution.ElectricHeatPump,
    Solution.CentralHeatingBoiler,
  ] as Solution[]).includes(solution);
}

/**
 * For ING customers during the pilot project, a price reduction should be applied to their quotes.
 * Context: At the time of writing, our prices are higher than the competition for the pilot,
 * and a price reduction is upcoming anyways
 * Some related ING changes have been made here too: src/components/installatron/InstallatronCore/InstallationBuilder.tsx
 *
 * Note: This all should be removed after the pilot project
 */
const INGRetailPriceReduction = ({ installation }: { installation: t_installation }) => {
  const { activeHouseId } = useActiveHouseId();

  const { data: ingData } = useQuery<houseIng, houseIngVariables>(HOUSE_ING, {
    variables: { houseId: activeHouseId },
  });

  const isINGHouse = ingData?.house.isINGHouse;
  const ingPriceReductionPct = needsINGPriceReduction(installation.solution) ? 0.075 : 0;

  const [updateInstallation, { loading, error }] = useMutation<
    updateInstallation,
    updateInstallationVariables
  >(UPDATE_INSTALLATION, {
    // Refetch installation to re-create the constraint messages
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: INSTALLATION_BY_HOUSE_SOLUTION,
        variables: { houseId: activeHouseId, solution: installation.solution },
      },
    ],
  });

  const handleSubmit = useCallback(async () => {
    const isConfirmed = window.confirm(
      'Weet je zeker dat je de ING korting wilt toepassen? Doe dit alleen wanneer alle production zijn toegevoegd, zodat de prijsverlaging op alle producten wordt toegepast.',
    );
    if (!isConfirmed) return;

    // Only allow the price reduction if all prices are up to date
    // (EXCEPT if tiered pricing is used, for insulation labor products only)
    const arePricesUpToDate = installation.items.every(i =>
      installation.solution in tieredPrices && i.product.category === ProductCategory.Labor
        ? i.price.retailPrice > i.retailPrice
        : i.price.retailPrice === i.retailPrice,
    );
    if (!arePricesUpToDate) {
      return window.alert(
        'Niet alle prijzen zijn up-to-date. Herstel de standaardprijzen (in de uitklapbare gele melding over de prijsafwijking) en probeer het opnieuw.',
      );
    }

    const installationItems: InstallationItemInput[] = installation.items.map(i => ({
      retailPrice: _.round(i.retailPrice * (1 - ingPriceReductionPct), 2),
      purchasePrice: i.purchasePrice,
      productId: i.product.id,
      supplierId: i.supplier.id,
      amount: i.amount,
    }));
    await updateInstallation({
      variables: {
        id: installation.id,
        installation: {
          id: installation.id,
          items: installationItems,
          solution: installation.solution,
        },
      },
    });
  }, [
    ingPriceReductionPct,
    installation.id,
    installation.items,
    installation.solution,
    updateInstallation,
  ]);

  if (!isINGHouse || !ingPriceReductionPct) return null;

  return (
    <Button
      inverse
      onClick={handleSubmit}
      label="ING korting"
      iconStart={LionFace}
      bgColor="orange"
      disabled={installation.items.length === 0}
      title="Prijsverlaging voor ING klanten toepassen"
      loading={loading}
      error={error?.message}
    />
  );
};

export default INGRetailPriceReduction;
