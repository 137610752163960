import { useMutation, useQuery } from '@apollo/client';
import {
  Accordion,
  Box,
  Button,
  Flex,
  Placeholder,
  Switch,
  Table,
  Toast,
} from '@energiebespaarders/symbols';
import { Medium, Right } from '@energiebespaarders/symbols/helpers';
import { Sync } from '@energiebespaarders/symbols/icons/solid';
import _, { sum } from 'lodash';
import {
  default as React,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIsMounted } from 'usehooks-ts';
import { useHasPricingPermission } from '../../../hooks/useHasPricingPermission';
import { delimit, fixUnit } from '../../../lib/utils';
import { UPDATE_INSTALLATION } from '../../../queries/installatron';
import { shade, themify } from '../../../styles/mixins';
import {
  getInstallationByIdPrices,
  getInstallationByIdPricesVariables,
  getInstallationByIdPrices_installationById_items_price as t_item_price,
} from '../../../types/generated/getInstallationByIdPrices';
import {
  installationByHouseSolution_installationByHouseSolution as t_installation,
  installationByHouseSolution_installationByHouseSolution_items as t_item,
} from '../../../types/generated/installationByHouseSolution';
import {
  updateInstallation,
  updateInstallationVariables,
} from '../../../types/generated/updateInstallation';
import { InstallationItemInput } from '../../../types/graphql-global-types';
import { INSTALLATION_BY_ID_PRICES } from './InstallationPriceIncrease';
import { IColumn } from './tableUtils';
import { houseIng, houseIngVariables } from '../../../types/generated/houseIng';
import { HOUSE_ING } from './INGRetailPriceReduction';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';

type PriceKey = 'retailPrice' | 'purchasePrice';

type UpdatableInstallationItem = t_item & {
  shownPriceType: PriceKey;
  /** current price in the product DB */
  defaultPrice: t_item_price;
  isOutOfDate: boolean;
};

/** A quote/installation price compared to that in the Product DB */
type PriceStatus = 'up-to-date' | 'lower' | 'higher';
/** The status of the price on the installation compared to what's the current prices are in the Product DB **/
export type PricingStatus = {
  /** The total quote/installation purchasePrice compared to that in the Product DB */
  purchasePrice: PriceStatus;
  /** The total quote/installation retailPrice compared to that in the Product DB */
  retailPrice: PriceStatus;
};

const outOfDateStyle = { color: themify('red'), fontWeight: 'strong' };

const columns: IColumn<UpdatableInstallationItem>[] = [
  {
    header: 'Product',
    width: 9 / 32,
    cell: function Product(item) {
      return item.product.title;
    },
    footer: function Total() {
      return <Medium>Totaal</Medium>;
    },
  },
  {
    header: 'Aantal',
    width: 3 / 32,
    cell: function Amount(item) {
      return (
        <>
          {item.amount} {fixUnit(item.product.priceUnit)}
        </>
      );
    },
  },
  {
    header: 'Huidige prijs',
    width: 1 / 8,
    cell: function CurrentSinglePrice(item) {
      return <Right block>€ {delimit(item[item.shownPriceType], 2)}</Right>;
    },
  },
  {
    header: 'Nieuwe prijs',
    width: 1 / 8,
    cell: function Amount(item) {
      return (
        <Right block style={item.isOutOfDate ? outOfDateStyle : null}>
          €{' '}
          {delimit(
            item.shownPriceType === 'purchasePrice'
              ? item.defaultPrice.purchasePrice
              : item.tieredRetailPrice ?? item.defaultPrice.retailPrice,
            2,
          )}
        </Right>
      );
    },
  },
  {
    header: 'Huidig subtotaal',
    width: 1 / 8,
    cell: function CurrentTotalPrice(item) {
      return <Right block>€ {delimit(item.amount * item[item.shownPriceType], 2)}</Right>;
    },
    footer: function CurrentTotalPriceSum(items) {
      return (
        <Right block>
          € {delimit(sum(items.map(item => item.amount * item[item.shownPriceType])), 2)}
        </Right>
      );
    },
  },
  {
    header: 'Nieuw subtotaal',
    width: 1 / 8,
    cell: function Amount(item) {
      return (
        <Right block style={item.isOutOfDate ? outOfDateStyle : null}>
          €{' '}
          {delimit(
            item.amount *
              (item.shownPriceType === 'purchasePrice'
                ? item.defaultPrice.purchasePrice
                : item.tieredRetailPrice ?? item.defaultPrice.retailPrice),
            2,
          )}
        </Right>
      );
    },
    footer: function Amount(items) {
      return (
        <Right block style={items.some(item => item.isOutOfDate) ? outOfDateStyle : null}>
          €{' '}
          {delimit(
            sum(
              items.map(
                item =>
                  item.amount *
                  (item.shownPriceType === 'purchasePrice'
                    ? item.defaultPrice.purchasePrice
                    : item.tieredRetailPrice ?? item.defaultPrice.retailPrice),
              ),
            ),
            2,
          )}
        </Right>
      );
    },
  },
];

const OutOfDatePriceChecker: React.FC<{
  installation: t_installation;
  pricingStatus: PricingStatus | undefined;
  setPricingStatus?: Dispatch<SetStateAction<PricingStatus | undefined>>;
  defaultOpen?: boolean;
  defaultPriceType?: PriceKey;
}> = ({ installation, setPricingStatus, defaultOpen, defaultPriceType = 'retailPrice' }) => {
  const { activeHouseId } = useActiveHouseId();
  const hasPricingPermission = useHasPricingPermission();

  const isMounted = useIsMounted();
  const canViewPurchasePrices = useHasPricingPermission();

  // for switching between which type of price to show in the table
  const [priceType, setPriceType] = useState<PriceKey>(defaultPriceType);

  const [updateInstallation, { loading, error }] = useMutation<
    updateInstallation,
    updateInstallationVariables
  >(UPDATE_INSTALLATION);

  const {
    data: installationPriceData,
    loading: instLoading,
    error: instError,
    refetch,
    networkStatus,
  } = useQuery<getInstallationByIdPrices, getInstallationByIdPricesVariables>(
    INSTALLATION_BY_ID_PRICES,
    {
      variables: { installationId: installation.id },
      notifyOnNetworkStatusChange: true, // needed since loading doesn't change when refetching
      // it might be in the cache already, but if not, the useEffect below will trigger a refetch
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    },
  );

  const instRefetching = networkStatus === 4;

  const itemsWithUpToDatePrices = installationPriceData?.installationById?.items;

  // When an installation changes (adding an item, changing order), might need to fetch new prices
  useEffect(() => {
    if (instLoading || instError) return;
    // If the installation contains a product that we don't know the price for, refetch it all
    // Could be optimized by only fetching that specific price, would mean extra complexity with caching
    //  would be nice to have a price(supplierId: ID!) resolver on product for that
    const productsIdsWithKnownPrices = itemsWithUpToDatePrices?.map(
      priceItem => priceItem.product.id,
    );
    if (
      // New item was added
      installation.items.some(item => !productsIdsWithKnownPrices?.includes(item.product.id)) ||
      // Changed order
      installation.items.some(
        (item, i) => item.product.id !== itemsWithUpToDatePrices?.[i].product.id,
      ) ||
      // Changed supplier (e.g. installer comparison tool)
      installation.items.some(
        item =>
          item.supplier.id !==
          itemsWithUpToDatePrices?.find(p => p.product.id === item.product.id)?.supplier?.id,
      )
    ) {
      // Even though the query is set to cache-first, this will fetch through the network
      refetch();
    }
  }, [instError, instLoading, installation, itemsWithUpToDatePrices, refetch]);

  const handleRestoreDefaultPrices = useCallback(async () => {
    if (!itemsWithUpToDatePrices?.length) return window.alert('Er is iets misgegaan');

    const installationItems: InstallationItemInput[] = installation.items.map(item => {
      const upToDatePrice = itemsWithUpToDatePrices.find(
        otherItem => otherItem.product.id === item.product.id,
      );
      return {
        retailPrice: item.tieredRetailPrice
          ? item.tieredRetailPrice
          : upToDatePrice!.price.retailPrice,
        purchasePrice: upToDatePrice!.price.purchasePrice,
        productId: item.product.id,
        supplierId: item.supplier.id,
        amount: item.amount,
      };
    });

    await updateInstallation({
      variables: {
        id: installation.id,
        installation: {
          id: installation.id,
          items: installationItems,
          solution: installation.solution,
        },
      },
    });
  }, [
    installation.id,
    installation.items,
    installation.solution,
    itemsWithUpToDatePrices,
    updateInstallation,
  ]);

  // Set up table data
  const newItems: UpdatableInstallationItem[] = useMemo(
    () =>
      !itemsWithUpToDatePrices
        ? []
        : installation.items.map((item, index) => ({
            ...item,
            shownPriceType: priceType,
            defaultPrice: itemsWithUpToDatePrices[index]?.price || {},
            isOutOfDate: item[priceType] !== itemsWithUpToDatePrices[index]?.price[priceType],
          })),
    [installation.items, itemsWithUpToDatePrices, priceType],
  );

  const { data: ingData } = useQuery<houseIng, houseIngVariables>(HOUSE_ING, {
    variables: { houseId: activeHouseId },
  });

  const isINGHouse = ingData?.house.isINGHouse;

  const isRetailOutOfDate = useMemo(
    () =>
      newItems.some(
        item => (item.tieredRetailPrice ?? item.defaultPrice.retailPrice) !== item.retailPrice,
      ),
    [newItems],
  );

  const isPurchaseOutOfDate = useMemo(
    () => newItems.some(item => item.defaultPrice.purchasePrice !== item.purchasePrice),
    [newItems],
  );

  const isOutOfDate = isRetailOutOfDate || isPurchaseOutOfDate;

  const messageSubject =
    isRetailOutOfDate && isPurchaseOutOfDate
      ? 'prijzen'
      : isRetailOutOfDate
      ? 'verkoopprijzen'
      : isPurchaseOutOfDate
      ? 'inkoopprijzen'
      : '';

  // Initialize the price type to which one is outdated after the installation is fetched
  useEffect(() => {
    if (!isMounted()) return;
    if (!isRetailOutOfDate && isPurchaseOutOfDate) setPriceType('purchasePrice');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationPriceData?.installationById]);

  useEffect(() => {
    if (!isMounted()) return;
    const newStatus: PricingStatus = { retailPrice: 'up-to-date', purchasePrice: 'up-to-date' };

    const quoteTotalRetail = _.sum(newItems.map(i => i.retailPrice * i.amount));
    const dbTotalRetail = _.sum(
      newItems.map(i => (i.tieredRetailPrice ?? i.defaultPrice.retailPrice) * i.amount),
    );

    newStatus.retailPrice =
      quoteTotalRetail === dbTotalRetail
        ? 'up-to-date'
        : quoteTotalRetail > dbTotalRetail
        ? 'higher'
        : 'lower';

    const quoteTotalPurchase = _.sum(newItems.map(i => i.purchasePrice * i.amount));
    const dbTotalPurchase = _.sum(newItems.map(i => i.defaultPrice.purchasePrice * i.amount));

    newStatus.purchasePrice =
      quoteTotalPurchase === dbTotalPurchase
        ? 'up-to-date'
        : quoteTotalPurchase > dbTotalPurchase
        ? 'higher'
        : 'lower';

    // Only update when the values have changed to prevent setState loop
    setPricingStatus?.(oldStatus => {
      if (JSON.stringify(newStatus) !== JSON.stringify(oldStatus)) {
        return newStatus;
      }

      return oldStatus;
    });
  }, [setPricingStatus, newItems, isMounted]);

  // The out-of-date price warning is a blocking alert if:
  // You don't have pricing permission and this is not an exception
  const isBlocking = !hasPricingPermission && isINGHouse;

  return !isOutOfDate ? null : instLoading || instError || instRefetching ? (
    <Placeholder error={instError} />
  ) : (
    <Toast
      type={isBlocking ? 'error' : 'alert'}
      message={
        <Accordion
          titleSize={5}
          basePadding={0}
          contentPadding={0}
          baseColor={shade(0.75, themify(isBlocking ? 'red' : 'orange'))}
          contentBgColor="transparant"
          title={`Sommige ${messageSubject} wijken af van het huidige aanbod`}
          unmountCollapsedContent
          defaultOpen={defaultOpen}
        >
          <Table layout={columns.map(col => col.width)}>
            <Table.Row isHeader>
              {columns.map((col, index) => (
                <Table.Cell key={col.header} textAlign={index > 1 ? 'right' : undefined}>
                  {col.header}
                </Table.Cell>
              ))}
            </Table.Row>
            {newItems.map(item => (
              <Table.Row key={item.product.id}>
                {columns.map(col => (
                  <Table.Cell key={col.header}>{col.cell(item, null as any /* n/a */)}</Table.Cell>
                ))}
              </Table.Row>
            ))}
            <Table.Row isFooter>
              {columns.map(col => (
                <Table.Cell key={col.header}>
                  <Medium>{col.footer?.(newItems)}</Medium>
                </Table.Cell>
              ))}
            </Table.Row>
          </Table>
          <Flex justifyContent="space-between">
            {!isRetailOutOfDate ||
              (canViewPurchasePrices && (
                <Box width={1 / 2} mt={4}>
                  <Switch
                    onLabel="Inkoopprijs"
                    offLabel="Verkoopprijs"
                    isOn={priceType === 'purchasePrice'}
                    toggleSwitch={() =>
                      setPriceType(priceType === 'purchasePrice' ? 'retailPrice' : 'purchasePrice')
                    }
                    bgColorOff="blue"
                    bgColorOn="orange"
                  />
                </Box>
              ))}
            <Box pt={2} alignText="right">
              <Button
                mr={0}
                mb={0}
                bgColor="blue"
                onClick={handleRestoreDefaultPrices}
                iconStart={Sync}
                loading={loading}
                error={error}
              >
                Standaardprijzen herstellen
              </Button>
            </Box>
          </Flex>
        </Accordion>
      }
      key="outOfDatePrices"
      width="100%"
      toastId={997}
    />
  );
};

export default OutOfDatePriceChecker;
