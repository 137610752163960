import { gql } from '@apollo/client';
import FileFragment from './File';

export const PvPanelFragment = gql`
  fragment PvPanel on PvPanel {
    advantages
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    backsheetColor
    cellType
    countryOfOrigin
    disadvantages
    frameColor
    height
    model
    model
    panelType
    pMax
    productWarranty
    series
    series
    tier
    warrantedPMax
    weight
    width
  }
`;

export const InverterFragment = gql`
  fragment Inverter on Inverter {
    advantages
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    connectionType
    countryOfOrigin
    depth
    disadvantages
    height
    mainsConnectionCurrentRequired
    maxOutputPower
    model
    monitoring
    monitoring
    noise
    phases
    pMax
    productWarranty
    series
    strings
    weight
    width
  }
`;

export const OptimizerFragment = gql`
  fragment Optimizer on Optimizer {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    countryOfOrigin
    series
    model
    advantages
    disadvantages
    productWarranty
    height
    width
    depth
    weight
  }
`;

export const InstallationMaterialFragment = gql`
  fragment InstallationMaterial on InstallationMaterial {
    materials
  }
`;

export const CompletePvSystemFragment = gql`
  ${PvPanelFragment}
  ${InverterFragment}
  ${OptimizerFragment}
  ${InstallationMaterialFragment}
  fragment CompletePvSystem on CompletePvSystem {
    panelAmount
    pMax
    panelId
    inverterId
    optimizerId
    installationMaterialId
    laborId
    pvPanel {
      id
      ...PvPanel
    }
    inverter {
      id
      ...Inverter
    }
    optimizer {
      id
      ...Optimizer
    }
    installationMaterial {
      id
      ...InstallationMaterial
    }
    labor {
      id
    }
  }
`;

export const WallCavityInsulationFragment = gql`
  fragment WallCavityInsulation on WallCavityInsulation {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    unitsPerHour
    rd
    thickness
    material
  }
`;

export const FloorInsulationFragment = gql`
  fragment FloorInsulation on FloorInsulation {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    unitsPerHour
    rd
    thickness
    material
  }
`;

export const RoofInsulationFragment = gql`
  fragment RoofInsulation on RoofInsulation {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    unitsPerHour
    rd
    thickness
    placement
    material
  }
`;

export const RoofFinishFragment = gql`
  fragment RoofFinish on RoofFinish {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    productWarranty
    unitsPerHour
    material
    finishType
    color
    paintable
  }
`;

export const GlazingFragment = gql`
  fragment Glazing on Glazing {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    unitsPerHour
    glassType
    thickness
    u
    zta
  }
`;

export const WindowFrameFragment = gql`
  fragment WindowFrame on WindowFrame {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    productWarranty
    unitsPerHour
    material
  }
`;

export const HeatPumpFragment = gql`
  fragment HeatPump on HeatPump {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    productWarranty
    advantages
    disadvantages
    heatingSettings
    heatPumpType
    minConnectionLoad
    maxElectricityConsumption
    minOutdoorTemperature
    necessaryFuses
    power
    scop35
    scop55
    splitUnit
    subsidy
    refrigerant
    refrigerantGWP
    sizeInternalUnit
    sizeExternalUnit
    weightExternalUnit
    maxFlowTemperature
    externalUnitNoise
  }
`;

export const CentralHeatingBoilerFragment = gql`
  fragment CentralHeatingBoiler on CentralHeatingBoiler {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    productWarranty
    releaseDate
    width
    height
    depth
    weight
    cwClass
    combiBoiler
    noise
    efficiency
    energyLabel
    openThermSupport
  }
`;

export const UnderfloorHeatingFragment = gql`
  fragment UnderfloorHeating on UnderfloorHeating {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    advantages
    disadvantages
    productWarranty
  }
`;

export const BoilerFragment = gql`
  fragment Boiler on Boiler {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    advantages
    disadvantages
    productWarranty
    height
    weight
    width
    depth
  }
`;

export const ThermostatFragment = gql`
  fragment Thermostat on Thermostat {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    productWarranty
    wireless
    programmable
    smartphoneApp
    communicationProtocol
    height
    width
    depth
  }
`;

export const FuseboxFragment = gql`
  fragment Fusebox on Fusebox {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    height
    width
    depth
    phases
    groups
    residualCurrentDevices
  }
`;

export const HeatingSystemFragment = gql`
  fragment HeatingSystem on HeatingSystem {
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    series
    model
    advantages
    disadvantages
    productWarranty
    unitsPerHour
    height
    width
    depth
    weight
    heatingSystemType
    power
  }
`;

export const MiscellaneousMainFragment = gql`
  fragment MiscellaneousMain on MiscellaneousMain {
    advantages
    disadvantages
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    productWarranty
    height
    width
    depth
    weight
    material
  }
`;

export const SedumFragment = gql`
  fragment SedumProduct on Sedum {
    advantages
    disadvantages
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    productWarranty
    weight
    material
  }
`;

export const ChargingBoxFragment = gql`
  fragment ChargingBoxProduct on ChargingBox {
    advantages
    disadvantages
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    productWarranty
    height
    width
    depth
    weight
  }
`;

export const HomeBatteryFragment = gql`
  fragment HomeBatteryProduct on HomeBattery {
    advantages
    disadvantages
    brand {
      id
      name
      logo {
        id
        extension
      }
    }
    productWarranty
    height
    width
    depth
    weight
    capacity
    power
    efficiency
  }
`;

export const LaborFragment = gql`
  fragment Labor on Labor {
    material
    thickness
  }
`;

// export const ServiceFragment = gql`
//   fragment Service on Service {
//     advantages
//     disadvantages
//   }
// `;

// export const OtherFragment = gql`
//   fragment Other on Other {
//     advantages
//     disadvantages
//   }
// `;

export const GenericProductFragment = gql`
  fragment GenericProduct on Product {
    id
    title
    description
    solution
    category
    priceUnit
    tax
    isdeCode
  }
`;

export const ProductFragment = gql`
  ${CompletePvSystemFragment}
  ${PvPanelFragment}
  ${InverterFragment}
  ${OptimizerFragment}
  ${FuseboxFragment}
  ${InstallationMaterialFragment}
  ${WallCavityInsulationFragment}
  ${FloorInsulationFragment}
  ${BoilerFragment}
  ${RoofInsulationFragment}
  ${RoofFinishFragment}
  ${GlazingFragment}
  ${WindowFrameFragment}
  ${CentralHeatingBoilerFragment}
  ${ThermostatFragment}
  ${HeatPumpFragment}
  ${HeatingSystemFragment}
  ${UnderfloorHeatingFragment}
  ${LaborFragment}
  ${MiscellaneousMainFragment}
  ${ChargingBoxFragment}
  ${HomeBatteryFragment}
  ${SedumFragment}
  ${FileFragment}
  fragment Product on Product {
    id
    title
    description
    internalDescription
    customMarkup
    solution
    category
    priceUnit
    tax
    isdeCode
    isPresentedOnQuote
    archived
    created
    youTubeId
    createdBy {
      ... on Operator {
        id
        firstName
        lastName
      }
    }
    files {
      ...File
    }
    ... on CompletePvSystem {
      ...CompletePvSystem
    }
    ... on PvPanel {
      ...PvPanel
    }
    ... on Inverter {
      ...Inverter
    }
    ... on Optimizer {
      ...Optimizer
    }
    ... on Fusebox {
      ...Fusebox
    }
    ... on InstallationMaterial {
      ...InstallationMaterial
    }
    ... on WallCavityInsulation {
      ...WallCavityInsulation
    }
    ... on FloorInsulation {
      ...FloorInsulation
    }
    ... on RoofInsulation {
      ...RoofInsulation
    }
    ... on RoofFinish {
      ...RoofFinish
    }
    ... on Glazing {
      ...Glazing
    }
    ... on WindowFrame {
      ...WindowFrame
    }
    ... on CentralHeatingBoiler {
      ...CentralHeatingBoiler
    }
    ... on UnderfloorHeating {
      ...UnderfloorHeating
    }
    ... on Boiler {
      ...Boiler
    }
    ... on Thermostat {
      ...Thermostat
    }
    ... on HeatPump {
      ...HeatPump
    }
    ... on HeatingSystem {
      ...HeatingSystem
    }
    ... on MiscellaneousMain {
      ...MiscellaneousMain
    }
    ... on Labor {
      ...Labor
    }
    ... on Sedum {
      ...SedumProduct
    }
    ... on ChargingBox {
      ...ChargingBoxProduct
    }
    ... on HomeBattery {
      ...HomeBatteryProduct
    }
  }
`;

/** Same as ProductFragment but without static fields */
export const ProductHistoryFragment = gql`
  ${CompletePvSystemFragment}
  ${PvPanelFragment}
  ${InverterFragment}
  ${OptimizerFragment}
  ${InstallationMaterialFragment}
  ${WallCavityInsulationFragment}
  ${FloorInsulationFragment}
  ${BoilerFragment}
  ${RoofInsulationFragment}
  ${GlazingFragment}
  ${WindowFrameFragment}
  ${CentralHeatingBoilerFragment}
  ${UnderfloorHeatingFragment}
  ${ThermostatFragment}
  ${HeatPumpFragment}
  ${HeatingSystemFragment}
  ${MiscellaneousMainFragment}
  ${ChargingBoxFragment}
  ${HomeBatteryFragment}
  ${SedumFragment}
  fragment ProductHistory on Product {
    id
    title
    description
    internalDescription
    customMarkup
    priceUnit
    tax
    isdeCode
    isPresentedOnQuote
    archived
    youTubeId

    files {
      ...File
    }

    ... on CompletePvSystem {
      ...CompletePvSystem
    }
    ... on PvPanel {
      ...PvPanel
    }
    ... on Inverter {
      ...Inverter
    }
    ... on Optimizer {
      ...Optimizer
    }
    ... on InstallationMaterial {
      ...InstallationMaterial
    }
    ... on WallCavityInsulation {
      ...WallCavityInsulation
    }
    ... on FloorInsulation {
      ...FloorInsulation
    }
    ... on RoofInsulation {
      ...RoofInsulation
    }
    ... on Glazing {
      ...Glazing
    }
    ... on WindowFrame {
      ...WindowFrame
    }
    ... on CentralHeatingBoiler {
      ...CentralHeatingBoiler
    }
    ... on UnderfloorHeating {
      ...UnderfloorHeating
    }
    ... on Boiler {
      ...Boiler
    }
    ... on Thermostat {
      ...Thermostat
    }
    ... on HeatPump {
      ...HeatPump
    }
    ... on HeatingSystem {
      ...HeatingSystem
    }
    ... on MiscellaneousMain {
      ...MiscellaneousMain
    }
    ... on Sedum {
      ...SedumProduct
    }
    ... on ChargingBox {
      ...ChargingBoxProduct
    }
    ... on HomeBattery {
      ...HomeBatteryProduct
    }
  }
`;

export default ProductFragment;
