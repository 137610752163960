import { useQuery } from '@apollo/client';
import { Box, Button, Card, Flex } from '@energiebespaarders/symbols';
import { Hardhat, List, SpecialDiscount } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { useActiveHouseId } from '../../../hooks/useActiveHouseId';
import { HOUSE_ADDRESS } from '../../../queries/house';
import { houseAddress, houseAddressVariables } from '../../../types/generated/houseAddress';
import { installationByHouseSolution_installationByHouseSolution as t_installation } from '../../../types/generated/installationByHouseSolution';
import { Solution } from '../../../types/graphql-global-types';
import InstallerComparisonModal from '../InstallerComparison/InstallationInstallerComparisonModal';
import TemplatePicker from '../QuoteTemplate/TemplatePicker';
import INGRetailPriceReduction from './INGRetailPriceReduction';
import InstallationDiscountForm from './InstallationDiscountForm';
import RetailPriceIncrease from './InstallationPriceIncrease';

interface DiscountProps {
  installation: t_installation;
}

const InstallationItemBreakdownFooter: React.FC<DiscountProps> = ({ installation }) => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [templatePickerOpen, setTemplatePickerOpen] = useState(false);
  const [installerComparisonOpen, setInstallerComparisonOpen] = useState(false);

  const solution = installation.solution as Solution;

  const { activeHouseId } = useActiveHouseId();
  const { data: houseData } = useQuery<houseAddress, houseAddressVariables>(HOUSE_ADDRESS, {
    variables: { houseId: activeHouseId },
  });
  const address = houseData?.house.address;

  return (
    <>
      {showDiscount && (
        <Card mb={2} p={2} animation="none" shadow="none" borderColor="grayLight">
          <Flex flexWrap="wrap" mx={-1}>
            <Box width={1} px={1}>
              <InstallationDiscountForm
                onClose={() => setShowDiscount(false)}
                installation={installation}
              />
            </Box>
          </Flex>
        </Card>
      )}

      <Flex>
        <Box width={1} textAlign="right">
          <INGRetailPriceReduction installation={installation} />

          <Button
            inverse
            onClick={() => setInstallerComparisonOpen(true)}
            label="Vergelijken"
            iconStart={Hardhat}
            bgColor="blue"
            disabled={installation.items.length === 0}
            title="Vergelijk de prijzen van de gekozen producten met die van andere installateurs"
          />
          {installerComparisonOpen && (
            <InstallerComparisonModal
              isOpen={installerComparisonOpen}
              closeModal={() => setInstallerComparisonOpen(false)}
              installation={installation}
              initialSupplierId={installation.items[0]?.supplier.id}
            />
          )}

          <Button
            inverse
            onClick={() => setTemplatePickerOpen(true)}
            label="Templates"
            iconStart={List}
            bgColor="blue"
          />
          {templatePickerOpen && address && (
            <TemplatePicker
              isOpen={templatePickerOpen}
              closeModal={() => setTemplatePickerOpen(false)}
              solution={solution as Solution}
              address={address}
              initialItems={installation.items.map(item => ({
                ...item,
                supplierId: item.supplier.id,
              }))}
            />
          )}

          <RetailPriceIncrease installation={installation} />

          {!showDiscount && (
            <Button
              iconStart={SpecialDiscount}
              inverse
              bgColor="blue"
              label={installation.discount.amount > 0 ? 'Korting aanpassen' : 'Korting toevoegen'}
              onClick={() => setShowDiscount(true)}
              mr={0}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};

export default InstallationItemBreakdownFooter;
